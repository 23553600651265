import { useState } from 'react';
const apiUrl = process.env.REACT_APP_API_URL;

const Forgotpassword = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const endpoint = 'forgotpassword';
    
    try {
      const response = await fetch(`${apiUrl}/api/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      console.log(data);
      if (data.status) {
        alert(data.message);
      } 
      else {
        alert(data.message);
      }
    } 
    catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="login_mainsec">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="login_boxsec">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="title_mainsec">
                        <h1>Forgot Password</h1>
                      </div>
                    </div>
                  </div>
                    
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="group-form">
                        <label className="form-label">Email address</label>
                        <input  
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required 
                          className="form-control" 
                          placeholder="Email address" 
                        />
                      </div>
                    </div>
                    
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="btn_mainsec">
                        <button type="submit" className="btn_style">Reset Password</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgotpassword;