import React, {  } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import './App.css';
import ScrollToTop from './components/ScrollToTop';
import Home from './components/Home';
import Login from './components/Login';
import Register from './components/Register';
import Forgotpassword from './components/Forgotpassword';
import Searchdomain from './components/Searchdomain';
import { saveSessionData, getSessionData } from './components/utils';
const homeUrl = process.env.REACT_APP_HOME_URL;

const App = () => {
  const user = getSessionData('user');
  const logout = () => {
    saveSessionData('user', null);
    window.location.href = homeUrl+'/login';
  };
  return (
    <Router>
      <ScrollToTop />
      <div className="header_mainsec fixed_sec">
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <a className="navbar-brand" href={`${homeUrl}`}><img src={`${homeUrl}/assets/img/main-logo.png`} /></a>
            {user ? <div className="right_box">
              <div className="dropdown profile_boxsec">
                <a className="dropdown_toggle" href="#">
                  <span className="profile_imgbox">
                    <img src={`${homeUrl}/assets/img/profile-default-img.png`} />
                  </span>
                
                  <span className="profile_txt">
                    {user.name} <i className="fas fa-chevron-down"></i>
                  </span>
                </a>
                
                <div className="dropdown-menu profile-dropdown">                
                  <a href="#" className="dropdown-item notify-item" onClick={logout}>
                    <i className="fas fa-sign-out-alt"></i>
                    <span>Logout </span>
                  </a>
                </div>
              </div>
            </div> :
            <div className="right_box">
              <div className="btn_mainsec">
                <a className="btn_style" href={`${homeUrl}/login`}>Get Access</a>
              </div>
            </div> }
          </div>
        </nav>
      </div>

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<Register />} />
        <Route exact path="/forgot-password" element={<Forgotpassword />} />
        <Route exact path="/search-domain" element={<Searchdomain />} />
      </Routes>

      <div className="footer_mainsec">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
              <div className="logo_cotntbox">
                <div className="logo_box">
                  <a href={`${homeUrl}`}><img src={`${homeUrl}/assets/img/main-logo.png`} /></a>
                </div>
                
                <div className="contnt_box">
                  <p>Lorem Ipsum is simply dummy text of the printing.</p>
                </div>
                
                <div className="social_mainsec" style={{width:'100%'}}>
                  <ul>
                    <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
            
            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
              <div className="footer_menu">
                <div className="menu_box">
                  
                </div>
              </div>
            </div>
            
            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
              <div className="address_box">
                <div className="title_sec">
                  <h3>Address</h3>
                </div>
                  
                <ul>
                  <li>
                    <div className="iconbox">
                      <i className="fas fa-map-marker-alt"></i>
                    </div>
                    
                    <div className="textbox">
                      <p>Address - 123</p>
                    </div>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default App;
