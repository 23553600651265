import { getSessionData } from './utils';
const homeUrl = process.env.REACT_APP_HOME_URL;

const Home = () => {
  const user = getSessionData('user');

  return (
  	<div>
      <div className="home_banner_mainsec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="banner_contntbox">
                <div className="top_budgesec">
                  <span>Automates the creation of email accounts</span>
                </div>
                
                <div className="title_sec">
                  <h1>Get more clients by scaling<br/> your cold email outreach</h1>
                  
                  <p>Mailscale is a software that helps you generate up to 1000 email accounts.<br/> As a result, you can send cold emails at scale and get more clients than you can handle.</p>
                  
                  <div className="btn_mainsec">
                  {user ? <a className="btn_style" href={`${homeUrl}/search-domain`}>Get Access</a> : <a className="btn_style" href={`${homeUrl}/login`}>Get Access</a> }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;