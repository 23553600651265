import { useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { saveSessionData, getSessionData } from './utils';
const homeUrl = process.env.REACT_APP_HOME_URL;
const apiUrl = process.env.REACT_APP_API_URL;

const Register = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cpassword, setCpassword] = useState('');

  const user = getSessionData('user');
  if(user) {
    return <Navigate to="/" replace />;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const endpoint = 'register';
    
    try {
      if(password !== cpassword) {
        alert('Confirm password mismatch');
        return false;
      }
      const response = await fetch(`${apiUrl}/api/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, password }),
      });

      const data = await response.json();

      if (data.status) {
        alert('Register in successfully!');
        saveSessionData('user', data.user);
        navigate('/');
      } 
      else {
        alert(data.message);
      }
    } 
    catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="login_mainsec">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="login_boxsec">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="title_mainsec">
                        <h1>Register</h1>
                      </div>
                    </div>
                  </div>
                    
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="group-form">
                        <label className="form-label">Name</label>
                        <input  
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required 
                          className="form-control" 
                          placeholder="Name" 
                        />
                      </div>
                    </div>
                    
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="group-form">
                        <label className="form-label">Email address</label>
                        <input  
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required 
                          className="form-control" 
                          placeholder="Email address" 
                        />
                      </div>
                    </div>
                    
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="group-form">
                        <label className="form-label">Password</label>
                        <input
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                          className="form-control" 
                          placeholder="Password"
                        />
                      </div>
                    </div>
                    
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="group-form">
                        <label className="form-label">Confirm Password</label>
                        <input
                          type="password"
                          value={cpassword}
                          onChange={(e) => setCpassword(e.target.value)}
                          required
                          className="form-control" 
                          placeholder="Confirm Password"
                        />
                      </div>
                    </div>
                    
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="btn_mainsec">
                        <button type="submit" className="btn_style">Submit</button>
                      </div>
                    </div>
                    
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="signup_link">
                        <span>I have an account? <a href={`${homeUrl}/login`}>Login</a></span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;