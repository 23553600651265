import React from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const CardForm = ({ handlePayment }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    // Create a token or payment method
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.error(error);
    } else {
      // Send the payment method to the server for payment processing
      handlePayment(paymentMethod.id);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement 
        className="stripe-input"
        options={{
              style: {
                base: {
                  fontSize: '16px',
                  color: '#0b0b13',
                  '::placeholder': {
                    color: '#4c545b',
                  },
                },
                invalid: {
                  color: '#0b0b13',
                },
              },
            }}
      />
      <div className="btn_mainsec">
        <br />
        <button className="btn_style" type="submit" disabled={!stripe}>Pay</button>
      </div>
    </form>
  );
};

export default CardForm;